
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String,
            default: 'title'
        },
        imgSrc: {
            type: String
        },
        active: {
            type: Boolean,
            default: false
        }
    }
});
